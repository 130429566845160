@tailwind base;
@tailwind components;
@tailwind utilities;

.right-shadow:after {
  box-shadow: 20px 0 20px -20px rgba(0, 0, 0, 0.3) inset;
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  right: -20px;
  width: 20px;
}

.right-shadow-hide:after {
  opacity: 0;
  transition: opacity 500ms;
}

.right-shadow-show:after {
  opacity: 1;
  transition: opacity 500ms;
}
